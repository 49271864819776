<template>
    <div>
        <b-card>  
            <div class="profile-container">
                <div class="nid-container top-gap">
                    <div class="no-scroll-part">
                        <p class="title capital-letter">{{ row.id_info.id_type }}</p>
                        <img :src="host + row.id_info.front_part_image" alt="front_part_image">
                        <img :src="host + row.id_info.back_part_image" alt="back_part_image">
                    </div>
                </div>
                <div class="info-container">
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Personal Information</p>
                            <b-button class="title-edit" @click="editPersonal(row)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <div class="table-row">
                                    <p>User:</p>
                                    <p>{{ row.personal_info.user }}</p>
                                </div>
                                <div class="table-row">
                                    <p>First Name:</p>
                                    <p>{{ row.user_object.first_name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Last Name:</p>
                                    <p>{{ row.user_object.last_name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Profile Id:</p>
                                    <p>{{ row.personal_info.profile_id }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Email:</p>
                                    <p>{{ row.user_object.email }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Phone No:</p>
                                    <p>{{ row.personal_info.phone_number }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Date of Birth:</p>
                                    <p>{{ row.personal_info.date_of_birth }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Age:</p>
                                    <p>{{ row.personal_info.age }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Gender:</p>
                                    <p class="capital-letter">{{ row.personal_info.gender }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Religion:</p>
                                    <p class="capital-letter">{{ row.personal_info.religion }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Height:</p>
                                    <p>{{ row.personal_info.height_ft }}.{{ row.personal_info.height_inch }}</p>
                                </div>
                            </div>
                            <div class="user-width">
                                <div class="table-row">
                                    <p>Marital Status:</p>
                                    <p class="capital-letter">{{ row.personal_info.marital_status }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Blood Group:</p>
                                    <p>{{ row.personal_info.blood_group }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Citizenship:</p>
                                    <p>{{ row.personal_info.citizenship.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Hometown:</p>
                                    <p>{{ row.personal_info.hometown.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Bio:</p>
                                    <p>{{ row.personal_info.bio }}</p>
                                </div>
                                <div class="table-row"> 
                                    <p>Profile Status:</p>
                                    <span v-if="row.personal_info.profile_status == 'active'"><b-badge pill variant="light-success">{{row.personal_info.profile_status}}</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-danger">{{row.personal_info.profile_status}}</b-badge></span>
                                </div>
                                <div class="table-row">
                                    <p>Premium Status:</p>
                                    <span v-if="row.personal_info.premium_status === 'premium'"><b-badge pill variant="light-success">Premium</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-info">Regular</b-badge></span>
                                </div>
                                <div class="table-row">
                                    <p>Phone Verified:</p>
                                    <span v-if="row.personal_info.phone_verified === true"><b-badge pill variant="light-success">Yes</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-danger">No</b-badge></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Educational Information</p>
                            <!-- <p class="title-edit">Edit</p> -->
                        </div>
                        <div class="education-container">
                            <div v-for="(education, index) in row.educational_info" :key="index">
                                <div class="table-row">
                                    <p>Degree:</p>
                                    <p v-if="education.degree">{{ education.degree.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Subject:</p>
                                    <p  v-if="education.subject">{{ education.subject.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Passing Year:</p>
                                    <p>{{ education.passing_year }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Institution:</p>
                                    <p  v-if="education.institution_name">{{ education.institution_name.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Country:</p>
                                    <p>{{ education.country }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Professional Information</p>
                            <!-- <p class="title-edit">Edit</p> -->
                        </div>
                        <div class="education-container">
                            <div v-for="(profession, index) in row.professional_info" :key="index">
                                <div class="table-row">
                                    <p>Industry:</p>
                                    <p  v-if="profession.industry_category">{{ profession.industry_category.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Designation:</p>
                                    <p v-if="profession.designation">{{ profession.designation.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Organization:</p>
                                    <p v-if="profession.organization_name">{{ profession.organization_name.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Family Information</p>
                            <!-- <p class="title-edit">Edit</p> -->
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <div class="table-row">
                                    <p>Father's Profession:</p>
                                    <p v-if="row.family_info.father_profession">{{ row.family_info.father_profession.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Father's Hometown:</p>
                                    <p v-if="row.family_info.father_hometown">{{ row.family_info.father_hometown }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Mother's Profession:</p>
                                    <p v-if="row.family_info.mother_profession">{{ row.family_info.mother_profession.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Mother's Hometown:</p>
                                    <p v-if="row.family_info.mother_hometown">{{ row.family_info.mother_hometown }}</p>
                                </div>
                            </div>
                            <div class="user-width">
                                <div class="table-row">
                                    <p>Number of Brothers:</p>
                                    <p>{{ row.family_info.number_of_brothers }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Number of Sisters:</p>
                                    <p>{{ row.family_info.number_of_sisters }}</p>
                                </div>
                                <div class="table-row">
                                    <p>Details:</p>
                                    <p>{{ row.family_info.family_details }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Picture</p>
                            <!-- <p class="title-edit">Edit</p> -->
                        </div>
                        <div>
                            <div>
                                <img class="image-size" :src="host + row.picture_info.mandatory_image" alt="row.picture_info.mandatory_image">
                            </div>
                            <div class="top-gap">
                                <img class="image-size" :src="host + row.picture_info.optional1_image" alt="row.picture_info.optional1_image">
                                <img class="image-size image-gap" :src="host + row.picture_info.optional2_image" alt="row.picture_info.optional2_image">
                                <img class="image-size" :src="host + row.picture_info.optional3_image" alt="row.picture_info.optional3_image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-card> 

        <b-modal
            v-if="updatePersonalMode"
            id="modal-xl"
            size="lg"
            centered
            title="Update Personal Information"
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
            >
                <!-- {{ personal }} -->
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="First Name"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.first_name"
                            :state="nameState"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Last Name"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.last_name"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Email"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.email"
                            :state="nameState"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Phone Number"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.phone_number"
                            :state="nameState"
                        />
                    </b-form-group>
                </div> 
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Date of Birth"
                        label-for="name-input"
                    >
                        <b-form-datepicker
                            id="example-datepicker"
                            v-model="personal.date_of_birth"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Gender"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.gender"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="gender_choice"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Religion"
                        label-for="name-input" 
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.religion"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="religion_choice"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Blood Group"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.blood_group"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Height (Feet)"
                        label-for="name-input" 
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.height_ft"
                            :state="nameState"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Height (Inches)"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.height_inch"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Marital Status"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.marital_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="marital_status_choice "
                            :reduce="name=>name.value" 
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="citizenship"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.citizenship"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="country"
                            :reduce="name=>name.id"
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div class="education-container"> 
                    <b-form-group
                        :state="nameState"
                        label="Hometown"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.hometown"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="district"
                            :reduce="name=>name.id"
                            label="name"
                        />
                    </b-form-group> 
                    <b-form-group
                        :state="nameState"
                        label="Profile Status"
                        label-for="fh-status"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.profile_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Premium Status"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.premium_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="prem_statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Phone Verified"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.phone_verified"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="verified_statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-group
                        :state="nameState"
                        label="Bio"
                        label-for="name-input"
                    >
                        <b-form-textarea
                            id="name-input"
                            v-model="personal.bio" 
                            :state="nameState"
                            rows="4"
                            resize
                        />
                    </b-form-group>
                </div>
            </form>
        </b-modal>
    </div>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea, BCalendar, BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FullCalendar from '@fullcalendar/vue';

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea,
        BCalendar,
        FullCalendar,
        BFormDatepicker
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            host: 'https://api-biyeruti.smicee.com',
            nameState: null,
            updatePersonalMode:false,
            updateStatusMode: false,
            popupActive:true,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            personal: {
                email: "",
                first_name: "",
                last_name: "",
                religion: null,
                blood_group: null,
                phone_number: null,
                date_of_birth: null,
                citizenship: null,
                gender: null,
                height_ft: null,
                height_inch: null,
                marital_status: null,
                bio: "",
                profile_status: null,
                premium_status: null,
                phone_verified: null
            },
            country_id:null,
            pageLength: 5,
            dir: false,
            showAdd:true,
            showEdit:true,
            showDelete:true,
            row: {},
            statuses: [ 
                {
                    name: 'OTP Pending',
                    value: 'otp_pending'
                },
                {
                    name: 'OTP Verified',
                    value: 'otp_verified'
                },
                {
                    name: 'Review',
                    value: 'review'
                },
                {
                    name: 'Further Review',
                    value: 'further_review'
                },
                {
                    name: 'Further Correction Due',
                    value: 'further_correction_due'
                },
                {
                    name: 'Checked',
                    value: 'checked'
                },
                {
                    name: 'Edit',
                    value: 'edit'
                },
                {
                    name: 'Edit Review',
                    value: 'edit_review'
                },
                {
                    name: 'Active',
                    value: 'active'
                },
                {
                    name: 'Incompatible',
                    value: 'incompatible'
                },
                {
                    name: 'Deleted',
                    value: 'deleted'
                }
            ],
            prem_statuses: [ 
                {
                    name: 'Premium',
                    value: 'premium'
                },
                {
                    name: 'Regular',
                    value: 'regular'
                }
            ],
            verified_statuses: [ 
                {
                    name: 'Yes',
                    value: true
                },
                {
                    name: 'No',
                    value: false
                }
            ],
            religion_choice: [
                {
                    name: 'Islam', 
                    value: 'islam'
                },
                {
                    name: 'Hinduism', 
                    value: 'hindu'
                },
                {
                    name: 'Buddhism', 
                    value: 'buddhism'
                },
                {
                    name: 'Christianity', 
                    value: 'christianity'
                },
                {
                    name: 'Others', 
                    value: 'others'
                }  
            ],
            gender_choice: [
                {
                    name: 'Male', 
                    value: 'male'
                },
                {
                    name: 'Female', 
                    value: 'female'
                }
            ],
            marital_status_choice : [
                {
                    name: 'Married', 
                    value: 'married'
                },
                {
                    name: 'Unmarried', 
                    value: 'unmarried'
                },
                {
                    name: 'Widow', 
                    value: 'widow'
                },
                {
                    name: 'Widower', 
                    value: 'widower'
                } 
            ]
        }
    },

    computed: {
        ...mapState('users', ["details","userError","userLoading"]),
        ...mapState('country', ["country","countryError","countryLoading"]),
        ...mapState('district', ["district","districtError","districtLoading"])
    },

    watch: {
        details(newValue, oldValue) {
            this.row=this.details
        }
    },

    created() {
        this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
        this.row = this.details
        let permissions=JSON.parse(localStorage.getItem('permissions'))

        this.$store.dispatch('country/fetchCountry')
        this.$store.dispatch('district/fetchDistrict')
    },

    methods:{
        editPersonal(data) {
            this.updatePersonalMode = true;
            this.personal.email = data.user_object.email,
            this.personal.first_name = data.user_object.first_name,
            this.personal.last_name = data.user_object.last_name,
            this.personal.religion = data.personal_info.religion,
            this.personal.blood_group = data.personal_info.blood_group,
            this.personal.phone_number = data.personal_info.phone_number,
            this.personal.date_of_birth = data.personal_info.date_of_birth,
            this.personal.citizenship = data.personal_info.citizenship.id,
            this.personal.hometown = data.personal_info.hometown.id,
            this.personal.gender = data.personal_info.gender,
            this.personal.height_ft = data.personal_info.height_ft,
            this.personal.height_inch = data.personal_info.height_inch,
            this.personal.marital_status = data.personal_info.marital_status,
            this.personal.bio = data.personal_info.bio,
            this.personal.profile_status = data.personal_info.profile_status,
            this.personal.premium_status = data.personal_info.premium_status,
            this.personal.phone_verified = data.personal_info.phone_verified
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },

        resetModal() {
            this.nameState = null
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        async handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            
            await this.$store.dispatch('users/updatePersonalInformations', {user:this.personal, pid:this.$route.params.id}).then(result=> {
                if(result.code == 200) {
                    this.$toast({
                        component: ToastificationContent, 
                        position: 'top-right',
                        props: {
                            title: `Success`,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                            text: result.response,
                        },
                    })

                    this.updatePersonalMode = false
                } else {
                    this.$toast({
                        component: ToastificationContent, 
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'ThumbsDownIcon',
                            variant: 'error',
                            text: result.response,
                        },
                    })
                }
            })

            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
        },

        handleStatusSubmit() {
            console.log(this.status.status) 
            this.$store.dispatch('users/updateAccountStatus', this.status).then(result=>{
                // console.log(result)
                if(result.code==200) {
                    this.updateStatusMode = false
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })
        },

        async confirmDelete(){
            await this.$store.dispatch("area/deleteUser", this.modify.id).then(result=>{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Success`,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                        text: result,
                    },
                })
            })
            this.$nextTick(() => {
                this.$refs['modal-delete'].toggle('#delete-btn')
            })
        }
    }
}
</script>
  
<style scoped>
.profile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8rem;
}

.nid-container {
    width: 25%;
}

.no-scroll-part {
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: fixed;
    gap: 1rem;
}

.info-container {
    width: 75%;
}

.user-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.user-width {
    width: 50%;
}

.table-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.title-row {
    display: flex;
    justify-content: space-between;
}

.title {
    color: rgb(75 85 99);
    font-size: 1.5rem;
    font-weight: 500;
}

.title-edit {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.capital-letter {
    text-transform: capitalize;
}

.top-gap {
    margin-top: 24px;
}

.education-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
}

.image-size {
    width: 160px;
}

.image-gap {
    margin-left: 18px;
    margin-right: 18px;
}
</style>